$.fn.timedropdown = function (opts) {

  if (opts === undefined) {
    opts = {};
  }

  /**
   * @type {Array}
   */
  const availableRanges = opts.availableRanges || [];

  /**
   * @type {number}
   */
  const interval = opts.interval || 1;

  /**
   * @type {boolean}
   */
  const ampm = !!opts.ampm;

  const _this = this;

  let time = getAllTime();

  if (availableRanges && availableRanges.length > 0) {
    time = time.filter(el => {
      return availableRanges
        .map(ar => ar.startTime[0])
        .includes(el[0]);
    });
  }

  this.html('');
  this.data('timepicker', this.val());

  for (let timeEntry of time) {
    const formattedTime = getFormattedTime(timeEntry[0], timeEntry[1]);

    const option = $('<option>', {value: formattedTime});
    option.text(formattedTime);
    this.append(option);
  }

  this.on('change', function () {
    _this.trigger('changeTime', _this.val());
  });

  function getAllTime() {
    let allTime = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += interval) {
        let time = [i, j];
        allTime.push(time);
      }
    }
    return allTime;
  }

  function getFormattedTime(hour, minute) {
    let H = '';
    let i;
    let a;

    function formatTimeUnit(timeUnit) {
      if (timeUnit.toString().length === 1) {
        return '0' + timeUnit;
      }
      return timeUnit;
    }

    if (ampm) {
      a = (hour < 12) ? 'AM' : 'PM';
      H = hour % 12 || 12;
    } else {
      H = hour;
    }

    H = formatTimeUnit(H);
    i = formatTimeUnit(minute);

    return ampm ? `${H}:${i} ${a}` : `${H}:${i}`;
  }

};